.search-widget {
  display: inline-block;
  @include search-box;
}
.header-top {
  .search-widget {
    form {
      input[type="text"] {
        min-width: inherit;
        width: 100%;
      }
    }
  }
}
#checkout {
  #search_widget {
    display: none; /* Not ideal solution by allows to reuse same hooks/templates */
  }
}

#pagenotfound {
  .page-content {
    #search_widget {
      width: 100%;
    }
  }
}

.page-not-found {
  .search-widget {
    form {
      display: inline-block;
    }
  }
}

/*** Responsive part ***/
@include media-breakpoint-down(sm) {
  .header-top {
    .search-widget {
      float: none;
      form {
        margin: 0 auto;
        input[type="text"] {
          min-width: inherit;
          background: white;
        }
      }
    }
  }
}
@include media-breakpoint-up(md) {
  .search-widget {
    min-width: 15.63rem;
  }
}
