html {
    ::placeholder { /* Firefox, Chrome, Opera */
    color: $black;
    opacity: 1;
  }
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: $black;
  }
  ::-ms-input-placeholder { /* Microsoft Edge */
      color: $black;
  }
}
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  letter-spacing: 1px;
  color: $main-color;
}

img {
    vertical-align: middle;
    max-width: 100%;
    height: auto;
}

.block-social li{
  height: 1.75rem;
  width: 1.75rem;
  background-color: transparent;
  background-size: contain;
  @include transition();
  @include filter(brightness(0));
  &:before {
    background-size: contain;
    @include transition();
    @include filter(brightness(0));
  }
  &:hover, &:hover:before {
    background-color: transparent;
    @include transition();
    @include filter(brightness(1));
  }
}
.facebook, .facebook:before,
.facebook.icon-gray, .facebook.icon-gray:before{
  background-image: url(../../assets/images/facebook.svg);
}
.youtube, .youtube:before,
.youtube.icon-gray, .youtube.icon-gray:before{
  background-image: url(../../assets/images/youtube.svg);
}
.pinterest, .pinterest:before,
.pinterest.icon-gray, .pinterest.icon-gray:before{
  background-image: url(../../assets/images/pinterest.svg);
}
.instagram, .instagram:before,
.instagram.icon-gray, .instagram.icon-gray:before{
  background-image: url(../../assets/images/instagram.svg);
}
.twitter, .googleplus {
  display: none !important;
}
.social-sharing li {
  background-color: transparent;
  @include border-radius(0);
  height: 2rem;
  width: 2rem;
  background-size: 1.5rem;
}

main, #wrapper {
  p a:not(.btn) {
    color: $main-color;
    text-decoration: underline;
    &:hover, &:focus {
      text-decoration: none;
      text-stroke: .5px;
      -webkit-text-stroke: .5px;
      text-stroke-color: #000;
      -webkit-text-stroke-color: #000;
    }
  }
}

p {
  color: $main-color;
  line-height: 1.75;
}

a, .btn, button, button[type=submit] {
  cursor: pointer;
  @include transition();
  &:hover, &:focus {
    outline: none;
    @include transition();
  }
}
.btn, button, button[type=submit] {
  &:hover, &:focus {
    text-decoration: none;
  }
}
.submit {
  .btn, .btn-default, button, button[type=submit]{
    @include btn-cta();
  }
}
.btn {
  &.btn-default {
    @include btn-default();
  }
}
.btn-primary, .btn-secondary, .btn-tertiary {
  @include btn-default();
  border: 0 none;
  box-shadow: none !important;
  &:hover, &:focus {
    border: 0 none;
    box-shadow: none !important;
  }
}
.btn-primary {
  @include btn-cta();
}
.btn-secondary {
  @include btn-more();
}
.btn-tertiary{
  @include btn-default();
}
.btn-clear-cart{
  margin-bottom: 2em;
}

h1, .h1, h2, .h2 {
  letter-spacing: .5px;
  font-weight: bold;
}
.h1, .h2, .h3, .h4, .h5, .h6 {
  color: $main-color;
  text-transform: initial !important;
}
.text-uppercase {
    text-transform: initial !important;
}

/*** Color ***/
.product-variants > .product-variants-item ul li {
  margin-right: .25rem;
}

.color,
.custom-checkbox input[type=checkbox]+span.color {
  @include border-radius(50%);
  border: 0 none;
}
.color,
.custom-checkbox input[type=checkbox]+span.color,
.facet-label .custom-checkbox span.color {
  @include border-radius(50%);
  border: 0 none;
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
  &:before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    @include border-radius(50%);
    background: $gray-light;
    transform-origin: center;
    @include scale(0.6);
    opacity: 1;
    @include transition(all .2s ease-in-out);
  }
  &.active, &:hover {
    border: 0 none;
    &:before {
      @include scale(0);
      opacity: 0;
      @include transition(all .2s ease-in-out);
    }
  }
}
.color.active, .color:hover,
.custom-checkbox input[type=checkbox]+span.color.active,
.custom-checkbox input[type=checkbox]+span.color:hover,
.facet-label.active .custom-checkbox span.color,
.facet-label:hover .custom-checkbox span.color {
  border: 0 none;
  &:before {
    @include scale(0);
    opacity: 0;
    @include transition(all .2s ease-in-out);
  }
}
.input-color, input-radio {
  + span {
    position: relative;
    &:before {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      @include border-radius(50%);
      background: $gray-light;
      transform-origin: center;
      @include scale(0.6);
      opacity: 1;
      @include transition(all .2s ease-in-out);
    }
  }
  &:checked, &:hover {
    + span {
      border: 0 none;
      &:before {
        @include scale(0);
        opacity: 0;
        @include transition(all .2s ease-in-out);
      }
    }
  }
}
/*** checkbox ***/
.custom-checkbox input[type=checkbox] {
  + span {
    border-color: $main-color;
    .checkbox-checked {
      color: $main-color;
      font-weight: 900;
      margin: -3px;
    }
  }
}

.label, label {
  color: $main-color;
}

.img-responsive {
  max-width: 100%;
  height: auto;
}

.container {
  width: 100%;
  overflow: hidden;
  padding-left: 2%;
  padding-right: 2%;
  @include media-breakpoint-down(lg){
    width: 100%;
    max-width: 100%;
  }
  @include media-breakpoint-down(md){
    width: 100%;
    max-width: 100%;
  }
   @include media-breakpoint-down(sm){
    width: 100%;
    max-width: 100%;
  }
  @include media-breakpoint-up(md){
    width: 100%;
    max-width: 100%;
  }
   @include media-breakpoint-up(sm){
    width: 100%;
    max-width: 100%;
  }
  @include media-breakpoint-down(xs){
    width: 100%;
    max-width: 100%;
  }
  @include media-breakpoint-up(sm){
    width: 100%;
    max-width: 100%;
  }
  @include media-breakpoint-up(xs){
    width: 100%;
    max-width: 100%;
  }
  @include media-breakpoint-only(sm){
    width: 100%;
    max-width: 100%;
  }
  @include media-breakpoint-only(xs){
    width: 100%;
    max-width: 100%;
  }
}
#wrapper {
  .container {
    @include media-breakpoint-down(sm){
      padding-top: 1.5rem;
    }
  }
  .breadcrumb {
    padding: 0;
    margin: 0 -15px;
    ol {
      padding: 15px 30px;
    }
    li{
       color: $main-color;
       a {
         color: $main-color;
         &:hover {
          color: $main-color;
          text-stroke: .5px;
          -webkit-text-stroke: .5px;
          text-stroke-color: #000;
          -webkit-text-stroke-color: #000;
         }
       }
       &:after {
        content: ">";
        color: $second-color;
        font-weight: 900;
        margin: 0;
      }
    }
  }
  h1.h1 {
    font-size: $font-size-h2;
    font-weight: 700;
  }
}

/*** HEADER ***/
#header {
  z-index: 5;
  position: relative;
  .header-nav {
    @include media-breakpoint-down(lg){
      font-size: 0.625rem;
    }
    .container {
      padding-left: 15px;
      width: 100%;
      max-width: 100%;
    }
    .row {
      @include flexbox();
      @include flex-direction(row);
      @include justify-content(space-between);
      @include align-items(center);
      > div {
        padding-top: 8px;
        padding-bottom: 8px;
      }
    }
    .baseline {
      margin-right: auto;
      background: $light;
      font-weight: 600;
      width: max-content;
    }
    .reassurance ul {
      margin: 0;
      padding: 0;
      width: 100%;
      @include flexbox();
      @include flex-direction(row);
      @include justify-content(flex-end);
      @include align-items(center);
      li {
        font-weight: 300;
        &:not(:last-child):after {
          content: "|";
          color: $second-color;
          margin: 0 5px;
        }
        &:not(:last-child) {
          @include media-breakpoint-down(md){
            display: none;
          }
        }
      }
    }
  }
  .header-top {
    padding: 0;
    background-color: $white;
    @include box-shadow(0 2px 0 0 $second-color);
    @include media-breakpoint-up(md){
      background: url(../../assets/images/bg-header-left.png) 200px center no-repeat, url(../../assets/images/bg-header-right.png) top right no-repeat;
    }
    >.container {
      overflow: initial;
      width: 100%;
      max-width: 100%;
    }
    .container > .row {
      @include flexbox();
      @include flex-direction(row);
      @include justify-content(space-between);
      @include align-items(center);
      padding: 15px 15px 5px;
      padding-right: 0;
      > div {
        padding-top: 8px;
        padding-bottom: 8px;
      }
    }
    .ask_quote.td_quote_top {
      display: none !important;
    }
    #_desktop_logo, #_mobile_logo {
      padding: 0;
      width: max-content;
      width: auto;
      img {
        max-width: calc(355px / 2);
        width: 100%;
        height: auto;
      }
    }
    #_desktop_logo {
      padding-right: 15px;
      img {
        padding-bottom: 15px;
        @media (max-width: 1300px){
          max-width: 150px;
        }
        @media (max-width: 1140px) and (min-width: 991px){
          max-width: 130px;
        }
      }
    }
    #_mobile_logo {
      margin: 0 auto;
      padding-right: 0;
      padding-left: 60px;
    }
    /*** wrapper menu ***/
    #_desktop_menu {
      @include flexbox();
      @include flex-direction(row);
      @include justify-content(flex-end);
      @include align-items(flex-end);
      padding: 0;
      margin: 0;
      margin-left: auto;
      @media (max-width: 1140px) and (min-width: 991px){
        width: auto;
      }
    }
    .mobile {
      @include flexbox();
      @include flex-direction(row);
      @include justify-content(space-between);
      @include align-items(center);
      width: 100%;
      padding: 15px 0;
    }
    /*** recherche ***/
    #search_widget {
      float: none;
      margin: 0;
      @include media-breakpoint-up(lg) {
          min-width: inherit;
      }
      form input[type=text] {
        border: 0 none;
        padding-left: 2.5rem;
        font-weight: 100;
        letter-spacing: 1px;
        color: $black;
        height: 38px;
        background: transparent;
        &:focus {
          outline: 0 none;
        }
        @media (max-width: 1140px) and (min-width: 991px){
          font-size: 90%;
          max-width: 130px;
        }
      }
      form button[type=submit] {
        bottom: 0;
        right: auto;
        left: .125rem;
        left: 0;
        padding: 0;
        margin: 0;
        &:hover, &:focus {
          i.material-icons {
            @include transition();
            @include filter(brightness(1));
          }
        }
        i.material-icons {
          @include transition();
          text-indent: -9999px;
          display: block;
          overflow: hidden;
          font-size: 38px;
          background: transparent url(../../assets/images/recherche-color@2x.png) no-repeat center / 20px auto;
          @include transition();
          @include filter(brightness(0));
        }
      }
    }
    /*** menu desktop ***/
    .menu.position-static {
      margin: 0 auto;
      padding: 0;
    }
    .top-menu {
      margin: 0 auto;
      padding: 0;
      a[data-depth="0"] {
        padding: 10px;
        font-size: $font-size-lg;
        @media (max-width: 1300px) and (min-width: 1141px){
          font-size: 96%;
        }
        @media (max-width: 1140px) and (min-width: 991px){
          font-size: 90%;
        }
        &:hover, &:focus {
          color: $second-color;
        }
      }
    }
    /*** menu mobile ***/
    #menu-icon {
      cursor: pointer;
      &:hover .material-icons {
        color: $second-color;
      }
    }
    #mobile_top_menu_wrapper {
      @include flexbox();
      @include flex-direction(column);
      #_mobile_menu {
        width: 100%;
        @include flexbox();
        @include flex-direction(row);
        @include justify-content(space-between);
        @include align-items(center);
        #block_wishlist_top .allert_note {
          display: none !important;
        }
      }
      #_mobile_top_menu, #top-menu {
        width: 100%;
      }
    }
    /*** icons menu right ***/
    #block_wishlist_top {
      position: relative;
      right: auto; left: auto;
      top: auto; bottom: auto;
      margin: 0 5px;
      .wish_link {
        float: none;
        position: relative;
        padding: 0;
        line-height: 20px;
      }
      a:hover, a:focus {
        i.material-icons {
          @include transition();
          @include filter(brightness(1));
        }
      }
      i.material-icons {
        @include transition();
        text-indent: -9999px;
        display: block;
        overflow: hidden;
        font-size: 38px;
        background: url(../../assets/images/favoris-color@x2.png) no-repeat center / 20px auto;
        @include transition();
        @include filter(brightness(0));
      }
      .allert_note {
        top: 40px;
        border: 0 none;
        box-shadow: none;
        background: $gray-lighter;
      }
      .wish_link > a {
        @include flexbox();
        @include flex-direction(column-reverse);
      }
      .wishlist_count {
        position: absolute;
        left: 10px;
        top: -15px !important;
        background: $second-color;
        color: $white;
        font-weight: 900;
        text-align: center;
        line-height: 20px;
        height: 20px;
        width: 20px;
        @include border-radius(50%);
        padding: 0;
        text-align: center;
        letter-spacing: 0px;
        &.empty_list {
          display: none;
        }
      }
    }
    .user-info {
      position: relative;
      margin: 0 5px;
      a:hover, a:focus {
        i.material-icons {
          @include transition();
          @include filter(brightness(1));
        }
      }
      i.material-icons {
        @include transition();
        text-indent: -9999px;
        display: block;
        overflow: hidden;
        font-size: 38px;
        background: url(../../assets/images/compte-color@x2.png) no-repeat center / 20px auto;
        @include transition();
        @include filter(brightness(0));
      }
      a.logout {
        display: none;
      }
      a.account {
        display: block;
        i.material-icons {
          &.hidden-md-up, &.logged {
            display: block !important;
          }
        }
      }
    }
    .blockcart.cart-preview {
      position: relative;
      margin: 0 5px;
      a:hover, a:focus {
        i.material-icons {
          @include transition();
          @include filter(brightness(1));
        }
      }
      i.material-icons {
        @include transition();
        text-indent: -9999px;
        display: block;
        overflow: hidden;
        font-size: 38px;
        background: url(../../assets/images/shopping-cart-color@x2.png) no-repeat center 55% / 20px auto;
        @include transition();
        @include filter(brightness(0));
      }
      .cart-products-count {
        position: absolute;
        left: 10px; top: -15px;
        background: $second-color;
        color: $white;
        font-weight: 900;
        text-align: center;
        line-height: 20px;
        height: 20px;
        width: 20px;
        letter-spacing: 0px;
        @include border-radius(50%);
      }
      .header {
        @include flexbox();
        @include flex-direction(column-reverse);
      }
    }
  }
  #contact-link {
    font-weight: 600;
    span {
      display: inline-block;
      position: relative;
      &:before {
        content: '\e0cd';
        font-family: 'Material Icons';
        position: relative;
        display: inline-block;
        float: left;
        font-size: 14px;
        margin-right: 5px;
        color: $second-color;
      }
    }
  }
}

/*** insta ***/
.aninsta_container {
  text-align: center;
  .aninstagramfeed-item {
    overflow: hidden;
    .aninstagramfeed-img {
      @include transition();
      @include scale(1);
    }
    &:hover, &:focus {
      .aninstagramfeed-img {
        @include transition();
        @include scale(1.05);
      }
    }
  }
}

/*** FOOTER ***/
#footer {
  img.logo {
    max-width: calc(355px / 2);
    height: auto;
  }
  .footer-top {
    #search_filters_brands {
      @include box-shadow(none);
      padding: 0;
      margin: 0 auto;
      h1 {
        display: none;
      }
      .facet > div{
        @include flexbox();
        @include flex-direction(row);
        @include justify-content(space-around);
        @include align-items(center);
        @include media-breakpoint-down(lg){
          @include flex-direction(column);
        }
      }
      .facet ul{
        padding: 0;
        margin: 0;
        @include flexbox();
        @include flex-direction(row);
        @include align-items(center);
        @include justify-content(space-between);
        width: calc(100% - 300px);
        @include media-breakpoint-down(lg){
          @include flex-wrap(wrap);
          @include justify-content(center);
          width: 100%;
        }
        @include media-breakpoint-down(md){
          li {
            width: 25%;
          }
        }
        @include media-breakpoint-down(xs){
          li {
            width: 50%;
          }
        }
        + a {
          @include btn-more;
        }
      }
      .facet-label {
        text-align: center;
        @include media-breakpoint-up(md){
          width: calc(100% / 10);
        }
        img {
          max-width: 100px;
          height: auto;
        }
      }
    }
  }
  .footer-middle {
    background-color: $cyan-lighter;
    background-image: url(../../assets/images/bg-large-footer.jpg);
    background-position: center 0;
    background-repeat: no-repeat;
    background-size: 100% auto;
    padding-top: 10vw;
    padding-bottom: 4rem;
    .container > .row {
      position: relative;
    }
    .links {
      .col-md-6.wrapper {
        &:first-child {
          p.h3 {
            display: none;
          }
          .custom-page-link {
            font-size: $font-size-h3;
            font-weight: bold;
          }
          ul li {
            margin-bottom: 1.25rem;
          }
        }
        &:nth-last-child(2), &:last-child {
          @include media-breakpoint-up(sm){
            @include make-col(3);
          }
        }
        ul li {
          margin-bottom: 1.25rem;
          a {
            color: $main-color;
            &:hover, &:focus {
              color: $second-color;
            }
          }
        }
      }
      p.h3 {
        font-size: $font-size-lg;
        font-weight: bold;
        text-transform: none !important;
        margin-bottom: 1.25rem;
      }
    }
    #block_myaccount_infos{
      .myaccount-title, .myaccount-title a {
        font-size: $font-size-lg;
        font-weight: bold;
        color: $main-color;
        margin-bottom: 1.25rem;
      }
      .account-list li {
        margin-bottom: 1.25rem;
        a {
          color: $main-color;
        }
      }
    }
    .block-contact {
      border: 0 none;
      color: $main-color;
      font-weight: bold;
      font-size: $font-size-lg;
      line-height: 1.125;
      letter-spacing: 2px;
      padding-left: 15px;
      padding-bottom: 4rem;
      @include media-breakpoint-down(md){
        margin-top: 3rem;
      }
      .logo {
        max-width: 230px;
        margin-bottom: 1.5rem;
      }
      span a {
        display: block;
        margin-bottom: 1.5rem;
        font-size: $font-size-h3;
      }
    }
    .block-social {
      margin-top: 1.5rem;
      text-align: left;
      position: absolute;
      right: 0;
      bottom: 0;
      @include media-breakpoint-up(lg){
        @include make-col-offset(9);
        @include make-col(3);
      }
      @include media-breakpoint-down(md){
        @include make-col(12);
      }
    }
    .links {
      .title {
        padding-left: 0;
        padding-right: 0;
        border-bottom: 1px solid $cyan-light-hover;
        .h3 {
          color: $main-color;
          font-weight: bold;
          font-size: $font-size-lg;
          line-height: 1.125;
          letter-spacing: 2px;
          text-transform: none;
        }
      }
      @include media-breakpoint-down(sm){
        .col-md-6.wrapper:last-child,
        .col-md-6.wrapper:nth-last-child(2) {
          width: 100%;
        }
      }
    }
  }
  .footer-bottom {
    color: $white;
    background-color: $second-color;
    padding: .5rem 0;
    p {
      color: $white;
      margin: .5rem 0;
    }
    a {
      color: $white;
      &:hover {
        color: $white;
        text-decoration: none;
        opacity: .8;
      }
    }
    @include media-breakpoint-down(md){
      .container >.row {
        @include flexbox();
        @include flex-direction(column-reverse);
      }
    }
    .links{
      .h3.hidden-sm-down {
        display: none;
      }
      .col-md-6{
        width: 100%;
      }
      ul {
        margin: 0;
        padding: 0;
        @include media-breakpoint-up(md){
          text-align: right;
        }
      }
      li{
        display: inline-block;
        &:not(:first-child):before {
          content: '|';
        }
        a {
          display: inline-block;
          color: $white;
          line-height: 1.75;
          margin: .5rem;
          &:hover {
            color: $white;
            text-decoration: none;
            opacity: .8;
          }
        }
      }
      @include media-breakpoint-down(md){
        li{
          display: block;
          &:before {
            display: none
          }
        }
        .title{
          display: none !important;
        }
        .collapse {
          display: block !important;
        }
      }
    }
  }
}


/*** FRONT ***/
#index main #wrapper .container {
  padding-left: 0%;
  padding-right: 0%;
}
.page-home{
  .main #wrapper .container {
    padding-left: 2%;
    padding-right: 2%;
  }
  .carousel{
    @include clearfix();
    .direction{
      display: none;
    }
    ul{
      overflow: inherit;
      height: auto;
      li{
        display: block !important;
        transform: none !important;
        &:nth-child(3),
        &:nth-child(4){
          width: 50%;
          float: left;
        }
      }
    }
    .carousel-item {
      @include clearfix();
      .caption{
        top: 0; bottom: 0;
        right: auto;
        @include flexbox();
        @include flex-direction(column);
        @include justify-content(center);
        @include align-items(flex-start);
        width: 100%;
        max-width: 90%;
        left: 5%;
        .caption-description h2 {
          font-weight: 700;
          font-size: $font-size-h1;
          @include media-breakpoint-down(md){
            font-size: 5vw;
          }
        }
        .caption-description p{
          margin-bottom: 0;
          margin-top: 2rem;
          @include media-breakpoint-down(md){
            margin-top: 2vw;
          }
        }
      }
      &:nth-child(2) .caption {
        @include align-items(flex-end);
        text-align: right;
        max-width: 100% !important;
        width: 90%;
        @include media-breakpoint-up(md){
          left: auto;
          right: 5%;
        }
      }
      &:nth-child(2) .caption, &:nth-child(3) .caption {
        .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
          color: #fff;
        }
      }
      /* btn style */
      .btn.btn-default {
        padding: 15px 45px;
        @include media-breakpoint-down(md){
          padding: 1.5vw 3vw;
          font-size: 1.5vw;
        }
      }
      &:nth-child(1), &:nth-child(4) {
        .btn.btn-default {
          @include btn-cta();
          padding: 15px 45px;
          @include media-breakpoint-down(md){
            padding: 1.5vw 3vw;
            font-size: 1.5vw;
          }
        }
      }

      &:nth-child(3) .caption, &:nth-child(4) .caption {
        max-width: 80%;
        left: 10%;
      }
      &:last-child figure{
        border: 2px solid $gray-light;
        img {
          width: 96.75%;
          margin-right: 0;
          margin-left: 3.25%;
        }
      }
      @include media-breakpoint-up(sm){
        &:nth-child(3) figure{
          margin-right: .5rem;
        }
        &:last-child figure {
          margin-left: .5rem;
        }
      }
    }
    .display-1 {
      letter-spacing: 1px;
      font-weight: 500;
      font-size: $font-size-lg;
      @include media-breakpoint-down(md){
        font-size: 2vw;
      }
    }
  }

  .home-block-text-image {
    margin: 6rem auto;
    width: 1470px;
    max-width: 100%;
    //border: 2px solid $gray-light;
    h2{
      text-align:center;
      margin: 0 auto 3rem;
    }
    .quincaillerie{
      background-image:url(../../assets/images/quincaillerie.jpg);
    }
    .sacoche{
      background-image:url(../../assets/images/sacoche-ouverture.jpg);
    }
    .quincaillerie, .sacoche{
      background-repeat:no-repeat;
      background-position:center;
      background-size:cover;
      min-height:25vw;
      @include media-breakpoint-down(sm){
        min-height:50vw;
      }
      display:flex;
      place-content:flex-start;
      place-items:flex-end;
      a{
        margin:0 0 20px 20px;
        @include media-breakpoint-down(sm){
          font-size:3vw!important;
        }
      }
    }
    .row {
      @include flexbox();
      @include flex-direction(row);
      @include justify-content(space-between);
      @include align-items(center);
      @include media-breakpoint-down(sm){
        @include flex-direction(column-reverse);
        gap:30px;
      }
    }
    .col-md-5 {
      margin: 3rem auto;
      padding: 0;
      left: 5%
    }
    p {
      letter-spacing: 1px;
      font-weight: 500;
      font-size: $font-size-lg;
      @include media-breakpoint-down(md){
        font-size: 2vw;
      }
    }
    h2 {
      font-weight: 700;
      font-size: $font-size-h1;
      @include media-breakpoint-down(md){
        font-size: 5vw;
      }
      + p {
        margin-bottom: 0;
      }
    }
    .btn {
      @include btn-cta();
      padding: 15px 45px;
      @include media-breakpoint-down(md){
        padding: 1.5vw 3vw;
        font-size: 1.5vw;
      }
    }
  }
  #custom-text {
    position: relative;
    text-align: justify;
    @include flexbox();
    @include flex-direction(column);
    @include justify-content(center);
    @include align-items(flex-start);
    margin: 3rem auto;
    @include media-breakpoint-up(sm){
      padding-right: 66%;
      min-height: 33vw;
      margin: 6rem auto;
    }
    @include media-breakpoint-down(sm){
      padding: 5%;
    }
    > p:first-child {
      @include make-col(12);
      @include aspect-ratio(16,9);
      margin-bottom: 3rem;
      @include media-breakpoint-up(md){
        @include make-col(7);
        position: absolute;
        float: none;
        right: 0;
        margin: 0;
      }
      iframe {
        width: 100%;
        height: 100%;
      }
    }
    p, h1, h2, h3, h4, h5, h6 {
       color: $main-color;
    }
    h2 {
      font-size: 3rem;
      font-weight: 700;
      text-transform: none !important;
      margin-bottom: 2rem;
      @include media-breakpoint-down(md){
        font-size: 5vw;
      }
    }
    p {
      font-size: $font-size-sm;
    }
    .dark {
       color: $main-color;
       font-weight: inherit;
    }
    .btn.btn-default {
      @include btn-link;
      margin-top: 1.5rem;
    }
  }
  /* titre section */
  .aninsta_container h2,
  #products .products-section-title,
  .featured-products .products-section-title,
  .product-accessories .products-section-title,
  .product-miniature .products-section-title {
    color: $main-color;
    font-weight: bold;
    margin: 0 auto 3rem;
    text-align: center;
    font-size: $font-size-h1;
    @include media-breakpoint-down(md){
      font-size: 5vw;
    }
  }

  .featured-products{
    @include clearfix();
    margin-left: auto;
    margin-right: auto;
    width: $container-width;
    max-width: 100%;
  }
}


/*** Blog ***/

.ybc_block_default, .ybc-blog-list, #index .block {
  .title_blog {
    color: $main-color;
    font-size: $font-size-h1;
    font-weight: bold;
    margin: 0 auto 3rem;
    text-transform: none !important;
    @include media-breakpoint-down(md){
      font-size: 5vw;
    }
    &.title_block {
      text-align: center;
    }
  }
  .all-blog-link {
    @include btn-more;
  }
  .block_content, .post-wrapper {
    li{
      text-align: left;
      cursor: pointer;
      &:hover, &:focus {
        .ybc_item_img img {
          @include transition();
          @include scale(1.05);
        }
        .read_more{
          color: $second-color;
          &:after {
            @include transition();
            @include filter(brightness(1));
          }
        }
      }
    }
    .ybc_item_img img {
      @include transition();
      @include scale(1);
    }
    .ybc_item_img + div:not(.ybc-blog-wrapper-content) {
      display: block;
      clear: left;
      padding: 3rem 15px;
      text-align: left;
    }
    .ybc-blog-latest-toolbar-views {
      display: none;
    }
    .blog_description {
      margin: 3rem auto 1.5rem;
      p {
        color: $main-color;
      }
    }
    a {
      color: $main-color;
      &:hover {
        text-decoration: none;
      }
    }
    .read_more {
      @include btn-link;
      font-style: normal;
      border-bottom: 0 none;
    }
  }
  .cat a {
    text-transform: uppercase;
    font-size: $font-size-sm;
    font-weight: 400;
  }
}

/* Pages blog */
#ybc_blog_page, #index {
  .ybc_title_block, .title_blog, .title_blog a {
    border-bottom: 0 none;
    margin: 0 auto 1.5rem;
    line-height: 1.6;
    font-weight: bold;
    font-size: $font-size-lg;
    color: $main-color;
    height: auto;
    padding: 0;
    text-transform: none;
    text-align: left;
  }
  .title_blog.title_block {
    text-align: center;
  }
  .blog_view_all_button {
    padding-left: 15px;
    padding-right: 15px;
  }
  a.blog_view_all, a.view_all_link{
    @include btn-more();
    color: $main-color !important;
    padding: 10px 15px !important;
    &:hover, &:focus {
      color: $main-color !important;
    }
  }

  #left-column, #right-column {
    .block{
      @include box-shadow(none);
      background: $gray-lighter;
      margin: 0 auto .5rem;
      text-align: left;
      &.ybc_block_default {
        background: $white;
        @include border-radius(0);
        border: 2px solid $gray-light;
      }
      a.blog_view_all, a.view_all_link{
        width: 100% !important;
      }
    }
  }
  .page-heading, .ybc-blog-wrapper .page-heading{
    font-size: $font-size-h2;
    text-transform: none;
    .title_cat {
      text-transform: none;
      margin: 3rem auto;
    }
  }
  .ybc-blog-tags-social a {
    @include border-radius(0);
  }
  .blog-submit .button {
    @include btn-cta();
    @include border-radius(0);
  }
  .form-polls {
    background: $gray-lighter;
  }
  .ybc_comment_form_blog {
    background: $gray-lighter;
    border: 0 none;
    h4 {
      padding: 30px 30px 0;
    }
    input[type="text"], select, #bc-subject, #bc-name, #bc-email {
      background: $white;
      color: $main-color;
      border: 2px solid $gray-light;
      &:focus, &:active{
        outline:none;
      }
    }
  }
}

.ybc_block_rss .title_blog.title_block > a i, .fa-rss {
    background: transparent !important;
    color: #000 !important;
    line-height: 28px !important;
}



/*** Products ***/
#products, .featured-products, .product-accessories, .product-miniature {
  color: $main-color;
  margin: 6rem auto;
  text-align: center;
  @include media-breakpoint-down(sm){
    margin: 3rem auto;
  }
  .all-product-link {
    @include btn-more;
  }
  .produit_promo .product-prices {
    .product-discount,
    .tax-shipping-delivery-label,
    .current-price span{
      display: none;
    }
  }

  .product-miniature {
    margin: 0 auto;
    .discount-amount, .discount-percentage, .on-sale, .online-only, .pack, .product-flags .new {
      padding: .25rem .5rem;
      font-size: $font-size-xs;
      min-height: auto;
      display: none;
    }
  }
  .thumbnail-container {
    max-width: 350px;
    width: 100%;
    height: auto;
    figure {
      position: relative;
      overflow: hidden;
      margin: 0 auto;
    }
    figure img {
      @include transition();
      @include scale(1);
      margin: 0;
      max-width: 100%;
      width: 100%;
      height: auto;
    }
    .img-thumbnail.manufacturer-logo {
      border: 0 none;
      margin: 0;
      padding: 0;
      max-height: 80px;
      opacity: .2;
    }

    &:hover, &:focus {
      figure img {
        @include transition();
        @include scale(1.05);
      }
      .highlighted-informations, .highlighted-informations.no-variants {
        top: 0;
        bottom: auto;
        opacity: 1;
      }
    }
    @include media-breakpoint-down(md){
      .product-description {
        @include box-shadow(none);
      }
    }
  }
  .product-thumbnail {
    @include aspect-ratio(1,1);
    border: 2px solid $gray-light;
    overflow: hidden;
  }
  .product-description {
    position: relative;
    width: 100%;
    height: auto;
    padding-bottom: 1.5rem;
    &:after {
      display: none;
    }
  }
  .product-title {
    margin-top: 0;
    a {
      color: $black;
      font-weight: bold;
      font-size: $font-size-lg;
      line-height: 1.3;
      span {
        display: block;
        text-transform: uppercase;
        font-size: $font-size-xs;
        font-weight: 400;
        letter-spacing: 2px;
      }
    }
  }
  .product-price-and-shipping {
    color: $gray-darker;
    font-size: $font-size-base;
    font-weight: 600;
    margin: .25rem auto;
  }
  .highlighted-informations {
    @include clearfix();
    z-index: 1;
    @include flexbox();
    @include flex-direction(column);
    @include justify-content(space-between);
    @include align-items(center);
    padding: 0;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    margin: 0 0 2px 2px;
    top: -100%;
    bottom: auto;
    background: transparent;
    @include box-shadow(none);
    opacity: 0;
    .quick-view {
      @include btn-more;
      &:hover {
        text-decoration: none;
      }
    }
    .variant-links {
      @include clearfix();
      background: transparent;
      top: auto;
      margin: auto auto 0;
    }
  }
}

/*** Reassurance ***/
#block-reassurance ul {
  @include flexbox();
  @include flex-direction(column);
  @include justify-content(flex-start);
  @include align-items(flex-start);
  background: transparent none;
  li {
    border-bottom: 0 none;
    .block-reassurance-item {
      @include flexbox();
      @include flex-direction(row);
      @include justify-content(space-between);
      @include align-items(center);
      padding: 10px;
      .picto {
        width: 30px;
        margin-right: .5rem;
        text-align: center;
      }
      img {
        height: 25px;
        width: auto;
        margin: 0 auto;
      }
      .h6 {
        font-size: $font-size-xs;
        margin: 0;
      }
    }
  }
}
.blockreassurance_product {
  @include flexbox();
  @include flex-direction(column);
  @include justify-content(flex-start);
  @include align-items(flex-start);
  background: transparent none;
  > div {
    @include flexbox();
    @include flex-direction(row);
    @include justify-content(space-between);
    @include align-items(center);
    padding: 10px;
  }
  .item-product {
    width: 30px;
    margin-right: .5rem;
    text-align: center;
    > img, > svg {
      height: 25px !important;
      width: auto !important;
      margin: 0 auto;
    }
  }
  p.block-title {
    font-size: $font-size-xs;
    margin: 0;
  }
}

/*** Qty ***/
.bootstrap-touchspin {
  border: 2px solid $gray-light;
  #quantity_wanted {
    border: 0 none;
    color: $black;
    font-size: $font-size-xs;
    font-weight: 600;
    width: 2rem;
    padding-right: 0px;
  }
  input.form-control, input.input-group {
    height: 2.65rem;
    border: 0 none;
    font-size: 1rem;
    font-weight: 600;
    color: $main-color;
  }
  .input-group-btn-vertical{
    border: 0 none;
    >.btn {
      display: block;
      padding: 0 .75rem;
      border: 0 none;
      &:hover {
        background: $white;
        color: $second-color;
      }
      i {
        font-size: 1.5rem;
        width: 1.5rem;
        height: 1.5rem;
        top: 0;
        left: 0;
        text-align: center;
      }
    }
  }
}

/*** Fiche Produit ***/
#product {
  color: $main-color;
  main #wrapper .container {
    padding-left: 0%;
    padding-right: 0%;
  }
  #wrapper .breadcrumb {
    position: relative;
    margin-bottom: 0;
    z-index: 0;
    @include media-breakpoint-up(lg){
      &:after {
        content: '';
        position: absolute;
        display: block;
        z-index: -1;
        background: $gray-lighter;
        top: 0;
        right: 0;
        bottom: 0;
        width: 41.66667%;
      }
    }
  }
  #content {
    position: relative;
    max-width: 100%;
    margin: 0 auto;
    padding: 0 15px;
    @include flexbox();
    @include flex-direction(column);
    @include justify-content(space-between);
    height: 100%;
  }
  #block-reassurance {
    background: transparent none;
    ul {
      @media (max-width:$container-width) {
        @include flex-direction(column);
        li {
          width: 100%;
          .block-reassurance-item {
            @include justify-content(flex-start);
          }
        }
      }
      @include media-breakpoint-down(md){
        @include flex-direction(row);

      }
      @include media-breakpoint-down(xs){
        @include flex-direction(column);
        li {
          width: 100%;
          .block-reassurance-item {
            @include justify-content(flex-start);
          }
        }
      }
    }
  }

  .wrapper-product {
    @include flexbox();
    @include flex-direction(row);
    @include justify-content(space-between);
    background: $gray-lighter;
    @include media-breakpoint-down(md){
      @include flex-direction(column);
    }
  }
  .wrapper-product-cover {
    background: $white;
    .product-flag, li.product-flag {
      display: none;
    }
    .product-manufacturer{
      position: absolute;
      z-index: 2;
      top: 30px;
      left: 30px;
      .img-thumbnail {
        background: transparent;
        border: 0 none;
        opacity: .25;
      }
    }
    .product-cover, .Magic360-container {
      @include flexbox();
      @include justify-content(center);
      @include align-items(center);
      @include media-breakpoint-up(sm){
      img{
        width: auto !important;
        height: auto;
        max-height: 75vh;
        max-width: 100%;
        }
      }
    }
    .product-.images-container, .scroll-box-arrows {
      position: relative;
    }
    .Magic360, .Magic360-container {
      border: 0 none;
    }
    .MagicToolboxContainer {
      position: relative;
      @include flexbox();
      @include flex-direction(column);
      @include justify-content(center);
      @include align-items(center);
      height: 100%;
      cursor: grab;
      .magic-slide.mt-active {
        @include flexbox();
        @include flex-direction(column);
        @include justify-content(center);
        @include align-items(center);
        height: 100%;
        width: 100%;
        max-width: 800px;
      }
      a, img {
        cursor: grab;
        &:focus {
          cursor: grabbing;
        }
      }
      .MagicToolboxMessage{
        position: relative;
        top: -3rem;
        display: inline-block;
        padding: 1rem;
        z-index: 2;
        margin: 0 auto 3rem;
        background: $gray-lighter;
        @include border-radius(25px);
      }
    }
    .product-features-before {
      ul {
        @include flexbox();
        @include flex-direction(row);
        @include flex-wrap(wrap);
        @include justify-content(space-between);
        @include align-items(center);
        margin-top: 1rem;
        padding: 0;
        height: 100%;
        @include media-breakpoint-up(lg){
          margin: 0;
        }
        li {
          border: 2px solid $gray-light;
          padding: 1rem 5%;
          width: 23%;
          height: 100%;
          strong {
            display: block;
          }
        }
        @include media-breakpoint-down(sm){
          li {
            width: 49%;
            margin-bottom: 1rem;
          }
        }
        @include media-breakpoint-down(xs){
          @include flex-direction(column);
          li {
            width: 100%;
          }
        }
      }
    }
  }
  .wrapper-product-info {
    padding: 60px 5% 30px;
    @include flexbox();
    @include flex-direction(column);
    @include justify-content(space-between);
    .product-manufacturer {
      font-weight: 600;
    }
    h1.h1 {
      font-size: 2.500rem;
    }
    .unit-size {
      text-transform: uppercase;
      font-size: $font-size-lg;
      letter-spacing: 2px;
      margin-bottom: 3rem;
    }

    .product-description {
      margin: 1rem auto;
    }
    .product-anchors {
      a {
        display: block;
        text-decoration: underline;
        margin-bottom: .5rem;
        &:hover {
          color: $main-color;
          text-stroke: .5px;
          -webkit-text-stroke: .5px;
          text-stroke-color: #000;
          -webkit-text-stroke-color: #000;
        }
      }
    }

    .product-actions{
      margin: 3rem auto;
      .control-label {
        font-weight: bold;
        font-size: $font-size-sm;
      }
      .add-to-cart {
        @include btn-cta();
        width: 100%;
        height: auto;
        .material-icons {
          display: none;
        }
      }
      .product-quantity {
        .qty {
          min-width: -moz-fit-content;
          min-width: max-content;
          .bootstrap-touchspin {
            border: 0 none;
            #quantity_wanted {
              height: 100%;
            }
            .input-group-btn-vertical{
              height: 100%;
              >.btn{
                height: 52%;
              }
            }
          }
        }
        .add {
          width: 100%;
        }
        .wish_link {
          display: inline-flex;
          padding: 0;
          margin: 0 .4rem .5rem 0;
          @include btn-default();
          padding: 10px;
          @include flexbox();
          @include flex-direction(column-reverse);
          &:hover, &:focus {
            i.material-icons {
              @include transition();
              @include filter(brightness(1));
            }
          }
          i.material-icons {
            @include transition();
            text-indent: -9999px;
            display: block;
            overflow: hidden;
            font-size: 38px;
            background: url(../../assets/images/favoris-color@x2.png) no-repeat center / 20px auto;
            @include transition();
            @include filter(brightness(0));
          }
          .wishlist_count {
            position: absolute;
            left: 10px;
            top: -15px !important;
            background: $second-color;
            color: $white;
            font-weight: 900;
            text-align: center;
            line-height: 20px;
            height: 20px;
            width: 20px;
            @include border-radius(50%);
            padding: 0;
            text-align: center;
            letter-spacing: 0px;
            &.empty_list {
              display: none;
            }
          }
        }
      }
      .ask_Quote_view{
        padding-left: 0 !important;
        .td_quote_cart_button{
          @include btn-default();
          width: 100%;
          height: auto;
          color: $main-color !important;
        }
      }
      #product-availability,
      .product-minimal-quantity,
      .product-additional-info {
        display: block;
        margin: 1rem auto;
        font-size: $font-size-xs;
        .product-unavailable {
          color: $main-color;
        }
      }
    }
    .product-variants {
      .product-variants-item{
        @include flexbox();
        @include flex-direction(row);
        @include justify-content(flex-start);
        @include flex-wrap(wrap);
        @include align-items(flex-start);
      }
      .control-label {
        width: auto;
        margin-right: 1rem;
        line-height: 1.5rem;
      }
    }
    .product-prices {
      .current-price span {
        font-size: 2.143rem;
        font-weight: bold;
        color: $second-color;
      }
      .tax-shipping-delivery-label {
        display: inline-block;
        color: $main-color;
        font-weight: bold;
        .delivery-information {
          display: none;
        }
      }
    }
    .product-add-to-cart {
      .control-label {
        display: none;
      }
    }
    .product-information {
      color: $black;
    }
    .social-sharing {
      display: none;
    }
    .block-reassurance {
      margin-top: auto;
    }
  }
  /* sections features */
  .product-features, .featured-products{
    max-width: $container-width;
    margin: 8rem auto;
    @media (max-width: $container-width){
      max-width: 100%;
    }
    @include media-breakpoint-down(md){
      margin: 3rem auto;
    }
    h2, .h2, .products-section-title{
      color: $main-color;
      font-weight: bold;
      margin: 0 auto 3rem;
      text-align: center;
      @include media-breakpoint-down(md){
        font-size: 5vw;
      }
    }
  }

  /* carateristiques */
  .product-features{
    .features {
      @include flexbox();
      @include flex-direction(row);
      @include justify-content(space-between);
      @include align-items(stretch);

      > div {
        width: 50%;
        @include flexbox();
        @include flex-direction(row);
        @include justify-content(flex-start);
        background: $gray-lighter;
        border: 2px solid $gray-light;
        margin: 15px;
        p.h6 {
          width: 30%;
          font-size: 1rem;
          font-weight: 800;
          padding: 2rem 2%;
          text-align: center;
        }
        .data-sheet {
          width: 70%;
          background: $white;
          margin: 0;
          padding: 5%;
          @include flexbox();
          @include flex-direction(row);
          @include flex-wrap(wrap);
          @include justify-content(flex-start);
          dt, dd {
            flex: 50%;
            margin: 0;
            padding: 8px;
          }
          @include media-breakpoint-down(xs){
            @include flex-direction(column);
            dt, dd {
              flex: 100%;
            }
          }
        }
      }
      @include media-breakpoint-down(md){
        @include flex-direction(column);
        > div {
          width: 100%;
          margin: 0 auto;
          @include flex-direction(column);
          p.h6, .data-sheet {
            width: 100%;
          }
        }
      }

    }
  }
}

.layout-full-width {
  .page-header {
    text-align: center;
  }
  #main > h1, page-header > h1 {
    margin: 4rem auto 5rem;
  }
}
.layout-left-column,.layout-right-column,
.layout-full-width:not(.page-index) {
  #wrapper .breadcrumb[data-depth="1"]{
    display: block;
    text-indent: -9999px;
    overflow: hidden;
  }
}

/*** Search filters ***/
#search_filters, #search_filters_suppliers {
  background: $gray-lighter;
  .h6 {
    color: $main-color;
    font-size: $font-size-sm;
    font-weight: bold;
  }
  div#_desktop_search_filters_clear_all {
    border-bottom: 1px solid;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    .js-search-filters-clear-all {
      width: 100%;
      .material-icons {
        text-indent: -9999px;
        overflow: hidden;
        font-size: 10px;
        margin-right: .25rem;
        background: url(../../assets/images/close@x2.png) no-repeat 50%/20px auto;
        @include transition();
        @include filter(brightness(0));
      }
      &:hover .material-icons {
        @include filter(brightness(1));
      }
    }
  }
  .facet {
    border-bottom: 1px solid;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    .facet-title {
      color: $main-color;
      font-size: $font-size-sm;
      font-weight: bold;
    }
    .facet-label {
      .custom-checkbox {
        top: .4375rem !important;
        position: relative;
      }
      a {
        color: $main-color;
        font-size: $font-size-xs;
        float: right;
        display: inline-block;
      }
      .custom-checkbox span.color {
        width: 1.125rem !important;
        height: 1.125rem !important;
      }
    }
  }
}

/*** Category ***/
#category, #new-products, #prices-drop {
  color: $main-color;
  /* aside */
  .block-categories {
    background: $cyan-lighter;
    .category-top-menu {
      li {
        margin-bottom: .5rem;
        a {
          color: $main-color;
          font-size: $font-size-h3;
          font-weight: bold;
          &:hover {
            color: $second-color;
          }
        }
      }
    }
  }
  #advansedwishlist_block {
    background: $white;
    border: 2px solid $gray-light;
    @include box-shadow(none);
    padding-bottom: 0;
    .title_block a {
      color: $main-color;
      font-size: $font-size-lg;
      font-weight: bold;
    }
    .block_content {
      color: $main-color;
      font-size: $font-size-sm;
      > a {
        @include btn();
        line-height: 1.5;
        border-top: 2px solid $gray-light;
        width: 100%;
        padding-left: 0;
        padding-right: 0;
        &:before {
          content: '';
          position: relative;
          z-index: 1;
          display: inline-block;
          float: right;
          width: 20px;
          height: 20px;
          background: $white url(../../assets/images/favoris-color@x2.png) no-repeat 50%/20px auto;
          @include transition();
          @include filter(grayscale(1));
        }
        &:hover:before {
          @include filter(grayscale(0));
        }
      }
    }
  }
  /* main */
  #products {
    margin-top: 0;
  }
  #main {
   h2.h2 {
      font-size: $font-size-h2;
      font-weight: 700;
    }
  }
  .block-category {
    position: relative;
    z-index: 0;
    padding: 1.563rem 0;
    &.card {
      border: 0 none;
      min-height: auto;
      padding: 0;
      @include flexbox();
      @include flex-direction(column);
      position: relative;
    }
    .category-cover {
      position: relative;
      right: auto;
      bottom: auto;
      @include aspect-ratio(5, 1);
      img {
        width: 100%;
        height: 100%;
      }
    }
    h1.h1 {
      font-weight: 700;
      font-size: $font-size-h3;

      @include media-breakpoint-up(md){
        position: absolute;
        top: 0; left: 0; right: 0; bottom: 0;
        z-index: 1;
        padding: 15px;
        @include flexbox();
        @include flex-direction(column);
        @include justify-content(center);
        height: 100%; width: 50%;
        padding: 15px 2%;
        margin: 0;
        
        font-size: 4vw;
      }
    }
  }
  #category-description, #category-description.text-muted {
    padding: 15px;
    border-top: 1px solid $gray-lighter;
    color: $main-color !important;
    text-align: justify;
    p {
      color: $main-color;
    }
  }
}
#js-product-list-bottom .block-category.card {
  min-height: auto;
  margin: 0 auto;
  padding: 0;
  border: 0 none;
}
/*** Pagination ***/
.active_filters {
  background: $cyan-lighter;
  .filter-block {
    color: $main-color;
    font-weight: bold;
    .close {
      color: $main-color;
      margin-top: 0;
    }
    &:hover {
      color: $second-color;
    }
  }
}
.total-products {
  text-align: left;
}
.products-selection {
  text-align: left;
  font-size: $font-size-xs;
  p {
    font-size: $font-size-xs;
  }
}
.products-sort-order {
  font-size: $font-size-xs;
  .select-title {
    font-size: $font-size-xs;
    color: $main-color;
    line-height: 2;
    border: 2px solid $gray-light;
  }
  .dropdown-menu {
    @include box-shadow(none);
    background: $gray-lighter;
  }
  .select-list {
    color: $main-color;
    &:hover {
      background: $gray-light;
    }
  }
}
.pagination {
  text-align: center;
  font-size: $font-size-xs;
  .page-list {
    display: inline-block;
    margin: 0 auto;
    li {
      display: inline-block;
      float: left;
      margin-left: .5rem;
    }
  }
  .current a, a {
    @include btn-more();
  }
  .spacer {
    @include btn();
    padding: 10px;
    line-height: $font-size-xs;
  }
  .material-icons {
    font-size: $font-size-lg;
  }
}
/*** Search page ***/
#search {
  .container {
    margin-left: auto;
    margin-right: auto;
    width: $container-width;
    max-width: 100%;
  }
  #js-product-list-header {
    text-align: center;
     font-size: $font-size-h1;
  }
}
#pagenotfound {
  .page-not-found {
    background: $gray-lighter;
    padding: 3rem 1rem;
    text-align: center;
    width: 100%;
    max-width: $screen-sm-min;
  }
}
/*** Pages CMS ***/
#cms {
  .container {
    margin-left: auto;
    margin-right: auto;
    width: $container-width;
    max-width: 100%;
  }
  #wrapper .container {
    width: 1140px;
    margin: 0 auto;
  }
  h1 {
    text-align: left;
  }
  h2 {
    font-size: $font-size-h3;
  }
  h3 {
    font-size: $font-size-h4;
  }
  h4 {
    font-size: $font-size-h5;
  }
  h5 {
    font-size: $font-size-h6;
  }
  h6 {
    font-size: $font-size-h3;
  }
  .elementor {
    .elementor-widget-heading .elementor-heading-title {
      line-height: 1.3;
    }
    .elementor-text-editor p {
      line-height: 1.6;
    }
  }
}

/*** Marques ***/
#manufacturer {
  .container {
    margin-left: auto;
    margin-right: auto;
    width: $container-width;
    max-width: 100%;
  }
  h1 {
    text-align: left;
  }
  #manufacturer-resume {
    @include clearfix();
    @include flexbox();
    @include flex-direction(row);
    @include justify-content(flex-start);
    @include media-breakpoint-up(sm){
      @include make-col(8);
      margin: 0 auto;
      float: none;
    }
    div#manufacturer-logo {
      width: inherit;
    }
  }
  #products {
    @include clearfix();
  }
  ul {
    li{
    }
    .brand {
      @include flexbox();
      @include flex-direction(row);
      @include justify-content(flex-start);
      @include align-items(center);
      border-bottom: 1px solid;
      padding: 1rem 15px;
      p {
        margin-bottom: 0;
      }
      @include media-breakpoint-down(sm){
        @include flex-direction(column);
        text-align: center;
      }
    }
    .brand-img {
      width: min-content;
      padding: 1rem;
      margin-right: 3rem;
      a {
        display: block;
        @include media-breakpoint-up(md){
          width: 100px;
        }
      }
      @include media-breakpoint-down(sm){
        width: 100%;
        margin: 0 auto;
        text-align: center;
      }
    }
    .brand-products {
      margin: 0 0 0 auto;
      a {
        @include btn-more();
      }
      @include media-breakpoint-down(sm){
        margin: auto 0 0 ;
        text-align: center;
        a {
          display: block;
          width: 100%;
          margin-top: .5rem;
        }
      }
    }
  }

  .footer-top {
    display: none;
  }
}
/*** formulaire ***/
.form-control, input[type="text"] {
  background: $white;
  color: $main-color;
  border: 2px solid $gray-light;
  .form-control:focus,
  .btn:focus, button:focus,
  .form-control:active,
  .btn:active, button:active{
    outline:none;
  }
}
.input-group {
  .form-control:focus{
    border: 0 none;
  }
  .input-group-btn {
    &:focus, &:active {
      outline:none;
      border: 0 none;
      @inlude box-shadow(none);
    }
    >.btn, >.btn[data-action=show-password] {
      @include btn-more();
    }
  }
}
.radio-inline{
  margin-right: 1rem;
}
.custom-radio {
  border-color: $main-color;
  width: 1rem; height: 1rem;
  top: -1px;
  input[type=radio]+span,
  input[type=radio]:checked+span {
    width: 8px;
    height: 8px;
    top: 2px;
    left: 2px;
  }
}
.custom-checkbox {
  border-color: $main-color;
  width: 1rem; height: 1rem;
  top: 2px;
  span {
    position: relative;
    margin-top: 0;
    top: -1px;
  }
}

/*** Authentication & Account ***/
#authentication,
.page-my-account,
.page-customer-account,
#module-advansedwishlist-mywishlist {
  .container {
    margin-left: auto;
    margin-right: auto;
    width: $container-width;
    max-width: 100%;
  }
  .page-content,
  .page-header, .page-heading {
    margin-bottom: 3rem;
    margin-top: 6rem;
  }
  .page-header, .page-heading, .page-subheading {
    text-align: center;
  }
  .page-footer{
    text-align: center;
    a {
      @include btn-more();
      @include media-breakpoint-down(md){
        display: block;
        margin: .5rem auto;
        width: 100%;
      }
    }
  }

  #notifications + h6 {
    text-align: center;
  }

  label {
    font-weight: bold;
  }

  .card-block {
    width: 100%;
    max-width: $screen-sm-min;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    border: 2px solid $gray-light;
  }
  #form_wishlist{
    max-width: 480px;
    width: 100%;
    margin: 3rem auto;
    margin-right: auto;
    label {
      width: 100%;
      text-align: left;
    }
    input, input.inputTxt{
      width: 100%;
      background: $white;
      color: $main-color;
      border: 2px solid $gray-light;
      padding: .5rem 1rem;
      height: auto;
    }
    .submit {
      button {
        @include btn-cta();
        width: 100%;
      }
    }
  }
}
.page-my-account {
  #content{
    .links {
      a {
        color: $main-color;
        span.link-item {
          background: $gray-lighter;
          border: 2px solid $gray-light;
        }
        i.material-icons, i.fa{
          height: 2.5rem;
          line-height: 1.5rem;
          display: block;
          padding: .5rem;
          margin: 0 auto 1rem;
          font-size: 1.5rem !important;
          color: #f6f6f6 !important;
          filter: drop-shadow(-1px -1px 0 $main-color) drop-shadow(1px 1px 0 $main-color) drop-shadow(-1px 1px 0 $main-color) drop-shadow(1px -1px 0 $main-color);

        }
        &:hover {
          color: $second-color;
          i.material-icons, i.fa {
            @include transition();
            filter: drop-shadow(-1px -1px 0 $second-color) drop-shadow(1px 1px 0 $second-color) drop-shadow(-1px 1px 0 $second-color) drop-shadow(1px -1px 0 $second-color);
          }
        }
        &#identity-link {
          i.material-icons{
            text-indent: -9999px;
            display: block;
            overflow: hidden;
            font-size: 38px;
            @include transition();
            @include filter(brightness(0));
            background: url(../../assets/images/compte-color@x2.png) no-repeat center / 20px auto;
          }
          &:hover i.material-icons{
            @include transition();
            @include filter(brightness(1));
          }
        }
        &#wishlist-link {
          i.material-icons{
            text-indent: -9999px;
            display: inline-block;
            overflow: hidden;
            font-size: 38px;
            @include transition();
            @include filter(brightness(0));
            background: url(../../assets/images/favoris-color@x2.png) no-repeat center / 20px auto;
          }
          &:hover i.material-icons {
            @include transition();
            @include filter(brightness(1));
          }
        }
        i.fa.fa-calculator{
          font-size: 1.25rem !important;
        }
      }
    }
  }
  .page-footer a {
    @include btn-more();
  }
}

/*** Contact ***/
#contact {
  #wrapper {
    .container {
      margin-top: 3rem;
      margin-left: auto;
      margin-right: auto;
      width: $container-width;
      max-width: 100%;
      @include flexbox();
      @include flex-direction(row-reverse);
      @include align-items(center);
      @include media-breakpoint-down(md){
         @include flex-direction(column-reverse);
      }
    }
    .breadcrumb {
      display: none;
    }
  }
  .contact-rich {
    @include clearfix();
    margin-top: 3rem;
    padding: 15px 25px;
    color: $main-color;
    background: $cyan-lighter;
    h4 {
      text-transform: none;
      color: $main-color;
      font-size: $font-size-h3;
    }
    .block {
      margin: 0 auto .5rem;
      line-height: 1.5;
      width: 100%;
      text-align: left;
      .material-icons {
        color: transparent;
        text-stroke: 2px;
        -webkit-text-stroke: 2px;
        text-stroke-color: #000;
        -webkit-text-stroke-color: #000;
      }
      .data {
        color: $main-color;
        letter-spacing: 2px;
        font-weight: bold;
      }
      a {
        font-weight: 900;
      }
    }
    hr {
      border-color: $main-color;
    }
  }

  #content-wrapper{
    @include media-breakpoint-up(lg){
      &.col-md-9 {
          width: 66.66667%;
      }
    }
    @include media-breakpoint-down(md){
      &.col-md-9 {
          width: 100%;
      }
    }
  }
  #main .page-content.card {
    border: 0 none;
  }
  .contact-form {
    padding: 0;
    h3 {
      text-transform: none;
      color: $main-color;
      font-size: $font-size-h2;
      font-weight: bold;
      text-align: left;
    }
    .form-group.row {
      > div {
        width: 100%;
        &.col-md-9.col-md-offset-3 {
          text-align: center;
        }
      }
      .form-control-comment {
        padding: .625rem 1rem;
        background: $cyan-lighter;
      }
      &:nth-child(4) > div.col-md-6 {
        width: 75%;
      }
    }
    .form-control-label {
      width: 100%;
      display: block;
      font-weight: bold;
      letter-spacing: 2px;
      text-align: left;
    }
  }
}

/*** Notifications ***/
#notifications{
  .container {
    padding: 0;
    text-align: center;
    font-weight: bold;
  }
}

/*** Cart & Checkout ***/
body#cart, body#checkout, body#module-askforaquotemodul-quotes {
  /* aside */
  .card.cart-summary, #js-checkout-summary.card {
    background-color: $gray-lighter;
    padding: 0;
    border: 0 none;
    .cart-summary-line {
      margin-bottom: 1rem;
      .label, .value {
        color: $main-color;
        font-size: $font-size-sm;
        font-weight: bold;
      }
      &#cart-subtotal-products {
        padding: 1rem;
        margin: 0 -1rem 1rem;
        background: $white;
      }
      &.cart-total {
        .label, .value {
          color: $second-color;
          font-size: $font-size-lg;
          font-weight: bold;
        }
      }
    }
    .cart-summary-products {
      padding: 0 0 1rem;
      margin: 0 -1rem;
      text-align: center;
      > p:first-child {
        background-color: $gray-light;
        font-weight: 600;
        letter-spacing: 2px;
        padding: 10px 15px;
      }
      a {
        @include btn-more();
        width: 100%;

      }
    }
    .checkout.cart-detailed-actions{
      .btn {
        display: block;
        width: 100%;
        &.btn-primary {
          @include btn-cta();
        }
      }
    }
  }

  /* reassurance */
  #block-reassurance ul {
    @include flex-direction(column);
    @include align-items(center);
  }
}

/*** Cart ***/
.separator {
  border: 1px solid $gray-light;
}

body#cart {
  color: $main-color;
  main #wrapper .container {
    padding-left: 0%;
    padding-right: 0%;
  }
  #wrapper .breadcrumb {
    position: relative;
    margin-bottom: 0;
    z-index: 0;
    @include media-breakpoint-up(lg){
      &:after {
        content: '';
        position: absolute;
        display: block;
        z-index: -1;
        background: $gray-lighter;
        top: 0;
        right: 0;
        bottom: 0;
        width: 33.33333%;
      }
    }
  }
  .card {
    border: 2px solid $gray-light;
  }
  .cart-grid {
    @include flexbox();
    @include flex-direction(row);
    height: 100%;
    @include media-breakpoint-down(md){
       @include flex-direction(column);
    }
    > div {
      padding: 0 5%;
      &.cart-grid-body {
        padding-bottom: 0;
        margin-bottom: 0;
      }
      &.cart-grid-right {
        background-color: $gray-lighter;
      }
    }
  }
  .cart-grid-body {
    .card-block + .separator {
      margin-bottom: 1rem;
    }
    .cart-container {
      padding-bottom: 1rem;
      margin: .5rem auto;
      .cart-item:not(:first-child) {
        border-top: 1px solid $gray-lighter;
      }
      .product-line-grid-body {
        .product-line-info{
          font-size: $font-size-xs;
          margin-bottom: .5rem;
          .label {
            font-size: $font-size-xs;
          }
          &:first-child {
            font-size: $font-size-sm;
            font-weight: bold;
          }
          &.product-price {
            font-size: $font-size-xs;
            font-weight: bold;
            color: $main-color;
          }
        }
      }
      .product-line-grid-right {
        .cart-line-product-actions {
          color: $main-color;
          @include flexbox();
          @include flex-direction(column);
          a, p {
            display: block;
            margin: .5rem auto;
          }
        }
        .product-price {
          font-size: $font-size-lg;
          color: $second-color;
        }
      }
      .remove-from-cart {
        i.material-icons {
          text-indent: -9999px;
          display: block;
          overflow: hidden;
          font-size: 0;
          width: 20px;
          height: 20px;
          background: url(../../assets/images/delete-color@x2.png) no-repeat 50%/20px auto;
          @include transition();
          @include filter(brightness(0));
        }
        &:hover i.material-icons {
          @include transition();
          @include filter(brightness(1));
        }
      }
      .wrap_allert{
        a {
          i.material-icons {
            text-indent: -9999px;
            display: block;
            overflow: hidden;
            font-size: 0;
            width: 20px;
            height: 20px;
            margin: 0 auto;
            background: url(../../assets/images/favoris-color@x2.png) no-repeat 50%/20px auto;
            @include transition();
            @include filter(brightness(0));
          }
          &:hover i.material-icons {
            @include transition();
            @include filter(brightness(1));
          }
        }
      }
      + .label {
        @include btn-more();
      }
    }
  }
  #advansedwishlist_cart_block {
    margin: 3rem auto;
    .title_block a {
      position: relative;
      &:before {
        content: '';
        z-index: 1;
        position: relative;
        display: inline-block;
        float: left;
        margin-right: .5rem;
        width: 20px;
        height: 20px;
        background: $white url(../../assets/images/favoris-color@x2.png) no-repeat 50%/20px auto;
        @include transition();
        @include filter(grayscale(1));
      }
      &:hover:before {
        @include transition();
        @include filter(grayscale(0));
      }
    }
    .title_block + .separator {
      margin: 1rem -1rem 0;
    }
    .block_content {
      padding: 1rem;
    }
  }
}
/*** demande de devis ***/
body#module-askforaquotemodul-quotes {
  color: $main-color;
  main #wrapper {
    .breadcrumb[data-depth="1"] {
      display: none;
    }
    #content-wrapper {
      margin-top: 3rem;
      margin-left: auto;
      margin-right: auto;
      width: $container-width;
      max-width: 100%;
    }
  }
  .title_header {
    text-transform: none;
    color: $main-color;
    font-size: $font-size-h2;
    font-weight: bold;
    text-align: left;
    background: transparent;
    border: 0 none;
  }
  .card {
    border: 2px solid $gray-light;
  }
  .quote_body_new_dbboo .table_block{
    .product_display:nth-child(4) > div {
      > div {
        display: inline-block;
        > div {
          @include flexbox();
          @include flex-direction(row);
        }
      }
      .up_count, .down_count {
        margin: 0 auto 0 .25rem;
      }
      input[type=text], .up_count, .down_count {
        border: 2px solid $gray-light !important;
        width: 40px;
      height: 40px;
      }
    }
    .del_btn.del_btn_pri {
      i.icon-trash {
        text-indent: -9999px;
        display: block;
        overflow: hidden;
        font-size: 0;
        width: 20px;
        height: 20px;
        background: url(../../assets/images/delete-color@x2.png) no-repeat 50%/20px auto;
        @include transition();
        @include filter(brightness(0));
      }
      &:hover i.icon-trash {
        @include transition();
        @include filter(brightness(1));
      }
    }
    .product_display:nth-child(5) {
      color:$second-color;
      font-weight: bold;
      font-size: $font-size-lg;
    }
  }
  .total_display {
    color:$second-color;
    font-weight: bold;
    font-size: $font-size-lg;
  }
  .text_input_dbboo {
    height: auto;
    padding: .5rem 1rem;
    @include box-shadow(none);
  }
  .btn.btn-default_mine_1 {
    @include btn-cta();
    border: 0 none;
  }
}

/*** Checkout ***/
body#checkout {
  color: $main-color;
  .card {
    border: 2px solid $gray-light;
  }
  #header {
    .header-nav {
      padding: 15px 15px 5px;
      background-color: $white;
      @include box-shadow(0 2px 0 0 $second-color);
      @include media-breakpoint-up(md){
        background: url(../../assets/images/bg-header-left.png) left center no-repeat, url(../../assets/images/bg-header-right.png) right top no-repeat;
      }
    }
    #_desktop_logo {
      display: block !important;
      width: 100%;
      text-align: center;
      img {
        max-width: calc(355px / 2);
        width: 100%;
        height: auto;
      }
    }
    .mobile, .text-xs-right {
      display: none !important;
    }
  }
  section#content {
    margin-bottom: 0;
    > .row {
      @include flexbox();
      @include flex-direction(row);
      height: 100%;
      @include media-breakpoint-down(md){
         @include flex-direction(column);
      }
      > div {
        padding: 5%;
        &.col-md-4 {
          background-color: $gray-lighter;
        }
      }
    }
  }
  section.checkout-step {
    background-color: $white;
    padding: 1rem;
    border-top: 3px solid $main-color;
    box-shadow: 0 3px 0 2px rgba(0,0,0,.1);
    &:last-child {
      border-top: 3px solid $main-color;
      box-shadow: 0 0 0 2px rgba(0,0,0,.1);
    }
    .step-title {
      font-size: $font-size-lg;
      font-weight: bold;
      .step-edit{
        @include btn-more();
        color: black !important;
        font-size: $font-size-xs;
        padding: 0.125rem .5rem;
      }
      .done {
        padding: 0;
      }
    }
    .step-number {
      background: $main-color;
      color: $white;
      width: 30px;
      height: 30px;
      line-height: 30px;
      font-size: 1em;
      text-align: center;
      padding: 0;
      margin-right: 1rem;
      @include border-radius(50%);
    }
    .content {
      margin: 2rem auto;
      a:not(#cta-terms-and-conditions-0) {
        @include btn-default();
      }
    }
    .address-footer{
      a {
        @include btn-more();
        color: black !important;
        font-size: $font-size-xs;
        i{
          margin-right: 1rem;
        }
      }
    }
    .nav.nav-inline {
      display: table;
      position: relative;
      vertical-align: middle;
      width: 100%;
      //border-top: 1px solid rgba(0,0,0,.1);
      //border-bottom: 1px solid rgba(0,0,0,.1);
      .nav-item {
        display: table-cell;
        width: 50%;
        text-align: center;
        &:nth-child(2n) {
          display: none;
        }
      }
      a.nav-link {
        @include btn-more();
        padding: 15px;
        width: 100%;
        &[aria-controls="checkout-login-form"] {
          @include btn-cta();
          padding: 15px;
        }
      }
    }
    .delivery-options {
      > .row {
        @include flexbox();
        @include flex-direction(row);
        @include align-items(center);
      }
    }
    label[for="delivery_message"] {
      text-align: left;
      padding: 1rem;
    }
    &.-current {
      border-top: 6px solid $second-color;
      .step-number {
        background: $second-color;
      }
    }
  }
  .summary-selected-carrier,
  .order-confirmation-table {
    > .row {
      @include flexbox();
      @include align-items(center);
      @include flex-wrap(wrap);
    }
  }
  .custom-checkbox {
    width: 100%;
    span {
      margin-top: 0;
    }
  }
  .condition-label{
    margin-left: 0;
    margin-top: 0;
    label {
      margin-left: -1rem;
      padding-left: 2.5rem;
      position: relative;
      z-index: 1;
    }
  }
  #footer {
    color: $white;
    background-color: $second-color;
    padding: .5rem 0;
    p {
      color: $white;
      margin: .5rem 0;
    }
    a {
      color: $white;
      &:hover {
        color: $white;
        text-decoration: none;
        opacity: .8;
      }
    }
  }
}

/*** Modal ***/
body{
  .modal-content {
    border: 2px solid $gray-lighter;
    background: $white;
  }
  .modal-body {
    background: $white;
    h1.h1 {
      font-size: $font-size-h3;
    }
  }
  .modal-footer {
    background: $white;
  }
  .quickview{
    .modal-content {
      border: 2px solid $gray-light;
      background: $white;
    }
    .modal-header {
      background: $gray-lighter;
      @include media-breakpoint-up(md){
        width: 50%;
        margin-left: 50%;
      }
    }
    .modal-body {
      padding: 0;
      > .row {
        margin: 0;
        height: 100%;
        background: $gray-lighter;
        @include flexbox();
        @include flex-direction(row);
        @include media-breakpoint-down(sm){
          @include flex-direction(column);
        }
        > div {
          padding: 15px;
          @include media-breakpoint-down(sm){
            width: 100%;
          }
          &:first-child {
            background: $white;
          }
        }
      }
      .product-prices {
        .current-price span {
          font-size: 2.143rem;
          font-weight: bold;
          color: $second-color;
        }
        .tax-shipping-delivery-label {
          display: inline-block;
          color: $main-color;
          font-weight: bold;
          .delivery-information {
            display: none;
          }
        }
      }
      .product-actions{
        margin: 3rem auto;
        .control-label {
          font-weight: bold;
          font-size: $font-size-sm;
        }
        .add-to-cart {
          @include btn-cta();
          width: 100%;
          height: auto;
          .material-icons {
            display: none;
          }
        }
        .product-quantity {
          .qty {
            min-width: -moz-fit-content;
            min-width: max-content;
            .bootstrap-touchspin {
              border: 0 none;
              #quantity_wanted {
                height: 100%;
              }
              .input-group-btn-vertical{
                height: 100%;
                >.btn{
                  height: 52%;
                }
              }
            }
          }
          .add {
            width: 100%;
          }
          .wish_link {
            display: inline-flex;
            padding: 0;
            margin: 0 .4rem .5rem 0;
            > a {
              @include btn-default();
              padding: 10px;
              @include flexbox();
              @include flex-direction(column-reverse);
            }
            a:hover, a:focus {
              i.material-icons {
                @include transition();
                @include filter(brightness(1));
              }
            }
            i.material-icons {
              @include transition();
              text-indent: -9999px;
              display: block;
              overflow: hidden;
              font-size: 38px;
              background: url(../../assets/images/favoris-color@x2.png) no-repeat center / 20px auto;
              @include transition();
              @include filter(brightness(0));
            }
            .wishlist_count {
              position: absolute;
              left: 10px;
              top: -15px !important;
              background: $second-color;
              color: $white;
              font-weight: 900;
              text-align: center;
              line-height: 20px;
              height: 20px;
              width: 20px;
              @include border-radius(50%);
              padding: 0;
              text-align: center;
              letter-spacing: 0px;
              &.empty_list {
                display: none;
              }
            }
          }
        }
        .ask_Quote_view{
          padding-left: 0 !important;
          .td_quote_cart_button{
            @include btn-default();
            width: 100%;
            height: auto;
            color: $main-color !important;
          }
        }
        #product-availability,
        .product-minimal-quantity,
        .product-additional-info {
          display: block;
          margin: 1rem auto;
          font-size: $font-size-xs;
          .product-unavailable {
            color: $main-color;
          }
        }
      }
    }
    .modal-footer {
      background: $white;
      border-top: 0 none;
      display: none;
    }
    .arrows {
      right: 7.5%;
      .arrow-down, .arrow-up {
        left: 0;
        right: 0;
      }
    }
  }
  #blockcart-modal {
    .modal-content {
      border: 2px solid $gray-lighter;
      background: $white;
    }
    .modal-body {
      background: $white;
    }
    .modal-footer {
      background: $white;
    }
  }
  .modal-backdrop {
    background-color: $gray-light;
    &.in {
      opacity: .85;
    }
  }
}
#search_filters{
  p + section.facet + section.facet,
  p + div + section.facet + section.facet {
    display: none;
  }
}

/* Module Scrolling information bar */
#header .scrolling-information-bar{
  background-color: $cyan;
  padding-top: 10px;
  .carousel-inner{
    height: auto;
    max-width: calc( 100% - 100px);
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    p{
      color: $white;
      @include media-breakpoint-down(sm){
        font-size: .75rem;
      }
      a{
        color: $white;
        &:hover{
          color: $white;
          text-shadow: none;
          text-stroke-color: transparent;
          -webkit-text-stroke-color: transparent;
          text-decoration: underline;
        }
      }
    }
  }
  .carousel-control{
    width: 75px;
    .icon-prev,
    .icon-next{
      i{
        line-height: 0.35;
        text-shadow: none;
        font-size: 2rem;
        opacity: .8;
        @media(min-width: $screen-xs) {
          line-height: 0.65;
        }
        &:hover{
          color: $white;
        }
      }
    }
    @include media-breakpoint-up(sm){
      .icon-next{
        right: 1.5rem;
      }
    }
  }
}
