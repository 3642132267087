.page-home{
    #search_filters_brands{
        .section-title{
            color: #000;
            font-weight: bold;
            margin: 0 auto 3rem;
            text-align: center;
            font-size: 3rem;
        }
        .facet > div{
            @include flexbox();
            @include flex-direction(row);
            @include justify-content(space-around);
            @include align-items(center);
            @include media-breakpoint-down(lg){
              @include flex-direction(column);
            }
          }
          .facet ul{
            padding: 0;
            margin: 0;
            @include flexbox();
            @include flex-direction(row);
            @include align-items(center);
            @include justify-content(space-between);
            width: calc(100% - 300px);
            @include media-breakpoint-down(lg){
              @include flex-wrap(wrap);
              @include justify-content(center);
              width: 100%;
            }
            @include media-breakpoint-down(md){
              li {
                width: 25%;
              }
            }
            @include media-breakpoint-down(xs){
              li {
                width: 50%;
              }
            }
            + a {
              @include btn-more;
            }
        }
        .facet-label {
            text-align: center;
            @include media-breakpoint-up(md){
              width: calc(100% / 10);
            }
            img {
              max-width: 100px;
              height: auto;
            }
        }      

    }
}
body#index .footer-top #search_filters_brands{ display:none;}