@import "~bourbon/app/assets/stylesheets/bourbon";
@import "partials/_variables";
@import "~bootstrap/scss/bootstrap";
@import "~bootstrap-touchspin/dist/jquery.bootstrap-touchspin.min.css";
@import "partials/_mixins";
@import "partials/_fonts";
@import "partials/_commons";
@import "components/alert";
@import "components/drop-down";
@import "components/search-widget";
@import "components/mainmenu";
@import "components/checkout";
@import "components/customer";
@import "components/forgotten-password";
@import "components/renew-password";
@import "components/imageslider";
@import "components/featuredproducts";
@import "components/custom-text";
@import "components/categories";
@import "components/products";
@import "components/cart";
@import "components/block-reassurance";
@import "components/quickview";
@import "components/stores";
@import "components/footer";
@import "components/contact";
@import "components/errors";
@import "components/customization-modal";
@import "components/sitemap";
@import "components/brandlist";
/*** HEADER ***/
#header {
  background: white;
  color: $black;
  .logo {
    max-width: 100%;
  }
  a {
    color: $gray-darker;
    &:hover {
      text-decoration: none;
      color: $brand-primary;
    }
  }
  .menu {
    display: inline-block;
    > ul {
      > li {
        display: inline-block;
      }
    }
  }
  .header-nav {
    background: $gray-lighter;
    max-height: 50px;
    font-size: $font-size-xs;
    color: $black;
    #menu-icon {
      vertical-align: middle;
      cursor: pointer;
      margin: 0 1rem;
      .material-icons {
        line-height: 50px;
      }
    }
    .right-nav {
      display: flex;
      flex-wrap: nowrap;
      justify-content: flex-end;
    }
    .currency-selector {
      margin-top: 0.9375rem;
      margin-left: 0.9375rem;
      white-space: nowrap;
    }
    .user-info {
      margin-left: 2.5rem;
      margin-top: 0.9375rem;
      text-align: right;
      white-space: nowrap;
      .account {
        margin-left: $small-space;
      }
    }
    .language-selector {
      margin-top: 0.9375rem;
      white-space: nowrap;
    }
    .cart-preview {
      &.active {
        background: $brand-primary;
        a, i {
          color: white;
        }
      }
      .shopping-cart {
        vertical-align: middle;
        color: $gray;
      }
      .body {
        display: none;
      }
    }
    .blockcart {
      background: $gray-light;
      height: 3rem;
      padding: 0.75rem;
      margin-left: 0.9375rem;
      text-align: center;
      white-space: nowrap;
      a {
        color: $gray;
        &:hover {
          color: $brand-primary;
        }
      }
      &.active {
        a:hover {
          color: white;
        }
      }
      .header {
        margin-top: 0.125rem;
      }
    }
    #_desktop_contact_link{
      display: inline-block;
    }
    .search-widget {
      margin-top: 0.2rem;
    }
    .material-icons {
      line-height: inherit;
      &.expand-more {
        margin-left: -0.375rem;
      }
    }
  }
  .header-top {
    > .container {
      position: relative;
    }
    padding-bottom: 1.25rem;
    .menu {
      padding-left: 15px;
      margin-bottom: 0.375rem;
    }
    .position-static {
      position: static;
    }
    a[data-depth="0"] {
      color: $black;
    }
    .search-widget {
      float: right;
    }
  }
  .top-menu-link {
    margin-left: $medium-space;
  }
  .top-menu .sub-menu{
    width: auto;
    min-width: auto;
    left: auto;
    right: auto;
  }
  .top-menu .sub-menu ul[data-depth="1"] > li{
    float: none;
  }
  .top-menu a.dropdown-submenu{
    text-transform: none;
    color: #000;
    font-weight: normal;
    padding-top: .625rem;
  }
}

.popover {
  font-family: inherit;
}
/*** WRAPPER ***/
#wrapper {
  background: $white;
  .banner {
    margin-bottom: 1.5rem;
    display: block;
  }
  .breadcrumb {
    background: transparent;
    padding: 0;
    &[data-depth="1"] {
      display: none;
    }
    ol {
      padding-left: 0;
      margin-bottom: 0;
    }
    li {
      display: inline;
      &::after {
        content: "/";
        color: $gray;
        margin: 0.3125rem;
      }
      &:last-child {
        content: "/";
        color: $gray;
        margin: 0;
        &::after {
          content: "";
        }
      }
      a {
        color: $gray-darker;
      }
    }
  }
}
/*** MAIN ***/
#main {
  .page-header {
    margin-bottom: 1.563rem;
  }
  .page-content {
    margin-bottom: 1.563rem;
    h6 {
      margin-bottom: 1.125rem;
    }
    #notifications {
      margin-left: -15px;
      margin-right: -15px;
    }
  }
  .page-footer {
    margin-bottom: 1.563rem;
  }
}
#notifications {
  ul {
    margin-bottom: 0;
  }
}
/*** FOOTER ***/
#footer {
  padding-top: 2.5rem;
}

/*** Responsive part ***/
@include media-breakpoint-down(lg) {
  #header {
    .header-nav {
      max-height: inherit;
      .search-widget {
       float: none;
       width: 15.63rem;
      }
    }
  }
}
@include media-breakpoint-down(md) {
  .container {
    max-width: 100%;
  }
  #header {
    .logo {
      width: auto;
    }
    .header-top {
      .search-widget {
        min-width: inherit;
      }
    }
  }
  #products .product-miniature,
  .featured-products .product-miniature {
    margin: 0 auto;
  }
  .sub-menu {
    left: 0;
    min-width: 100%;
  }
  #blockcart-modal .product-image {
    width: 100%;
    display: block;
    max-width: 15.63rem;
    margin: 0 auto 0.9375rem;
  }
  #blockcart-modal .cart-content {
    padding-left: 0;
  }
  #blockcart-modal .product-name,
  #product-availability {
    margin-top: $small-space;
  }
  #search_filters .facet .facet-label {
    text-align: left;
  }
  .block-category .category-cover {
    position: relative;
    text-align: center;
  }
  .block-category {
    padding-bottom: 0;
  }
}
@include media-breakpoint-down(sm) {
  #wrapper {
    box-shadow: none;
  }
  #checkout-cart-summary {
    float: none;
    width: 100%;
    margin-top: 1rem;
  }
  #header {
    .header-nav {
      .top-logo {
        padding-top: 11px;
        a {
          img {
            max-height: 50px;
            max-width: 100%;
          }
        }
      }
      .right-nav {
        flex-direction: column;
      }
      .user-info {
        text-align: left;
        margin-left: 0;
        .logged {
          color: $brand-primary;
        }
      }
      .blockcart {
        margin-left: 0;
        background: inherit;
        &.active {
          margin-left: 0.5rem;
        }
        &.inactive {
          .cart-products-count {
            display: none;
          }
        }
      }
    }
    .header-top {
      background: $gray-light;
      padding-bottom: 0;
      a[data-depth="0"] {
        color: $black;
      }
      .search-widget {
        width: 100%;
      }
    }
    &.is-open {
      background-color: white;
      .header-top {
        background-color: white;
      }
    }
  }
  section.checkout-step {
    width: 100%;
  }
  .default-input {
    min-width: 100%;
  }
  label {
    clear: both;
  }
  #products .product-miniature,
  .featured-products .product-miniature {
    margin: 0 auto;
  }
  .block-contact {
    padding-left: 0.9375rem;
    border: none;
  }
  .menu,
  .dropdown-item {
    padding-left: 0;
  }
  #footer {
    padding-top: 0.5rem;
  }
}

@include media-breakpoint-down(xs) {
  #left-column, #content-wrapper {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

@import "custom";

// Module carte cadeaux
#module-thegiftcard-page{
  .page-content{
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
  }
  #giftcard_product .content_scene_cat{
    .content_scene_cat_bg{
      padding-top: 400px;
      background-size: cover !important;
      @include media-breakpoint-down(xs) {
        padding-top: 200px;
        min-height: 260px !important;
      }
    }
  }
}