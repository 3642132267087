// Flexbox display
@mixin flexbox() {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

// The 'flex' shorthand
// - applies to: flex items
// <positive-number>, initial, auto, or none
@mixin flex($values) {
  -webkit-box-flex: $values;
     -moz-box-flex: $values;
      -webkit-flex: $values;
  	  -ms-flex: $values;
  	      flex: $values;
}

// Flex Flow Direction
// - applies to: flex containers
// row | row-reverse | column | column-reverse
@mixin flex-direction($direction) {
  -webkit-flex-direction: $direction;
     -moz-flex-direction: $direction;
      -ms-flex-direction: $direction;
          flex-direction: $direction;
}

// Flex Line Wrapping
// - applies to: flex containers
// nowrap | wrap | wrap-reverse
@mixin flex-wrap($wrap) {
  -webkit-flex-wrap: $wrap;
     -moz-flex-wrap: $wrap;
      -ms-flex-wrap: $wrap;
          flex-wrap: $wrap;
}

// Flex Direction and Wrap
// - applies to: flex containers
// <flex-direction> || <flex-wrap>
@mixin flex-flow($flow) {
  -webkit-flex-flow: $flow;
     -moz-flex-flow: $flow;
      -ms-flex-flow: $flow;
          flex-flow: $flow;
}

// Display Order
// - applies to: flex items
// <integer>
@mixin order($val) {
  -webkit-box-ordinal-group: $val;  
     -moz-box-ordinal-group: $val;     
  	     -ms-flex-order: $val;     
  	      -webkit-order: $val;  
  		      order: $val;
}

// Flex grow factor
// - applies to: flex items
// <number>
@mixin flex-grow($grow) {
  -webkit-flex-grow: $grow;
     -moz-flex-grow: $grow;
      -ms-flex-grow: $grow;
          flex-grow: $grow;
}

// Flex shrink
// - applies to: flex item shrink factor
// <number> 
@mixin flex-shrink($shrink) {
  -webkit-flex-shrink: $shrink;
     -moz-flex-shrink: $shrink;
      -ms-flex-shrink: $shrink;
          flex-shrink: $shrink;
}

// Flex basis
// - the initial main size of the flex item
// - applies to: flex itemsnitial main size of the flex item
// <width> 
@mixin flex-basis($width) {
  -webkit-flex-basis: $width;
     -moz-flex-basis: $width;
      -ms-flex-basis: $width;
          flex-basis: $width;
}

// Axis Alignment
// - applies to: flex containers
// flex-start | flex-end | center | space-between | space-around 
@mixin justify-content($justify) {
  -webkit-justify-content: $justify;
     -moz-justify-content: $justify;
      -ms-justify-content: $justify;
          justify-content: $justify;
            -ms-flex-pack: $justify;
}

// Packing Flex Lines
// - applies to: multi-line flex containers
// flex-start | flex-end | center | space-between | space-around | stretch 
@mixin align-content($align) {
  -webkit-align-content: $align;
     -moz-align-content: $align;
      -ms-align-content: $align;
          align-content: $align;
}

// Cross-axis Alignment
// - applies to: flex containers
// flex-start | flex-end | center | baseline | stretch 
@mixin align-items($align) {
  -webkit-align-items: $align;
     -moz-align-items: $align;
      -ms-align-items: $align;
       -ms-flex-align: $align;
          align-items: $align;
}

// Cross-axis Alignment
// - applies to: flex items
// auto | flex-start | flex-end | center | baseline | stretch 
@mixin align-self($align) {
  -webkit-align-self: $align;
     -moz-align-self: $align;
      -ms-align-self: $align;
          align-self: $align;
}

// search-box
@mixin search-box {
  form {
    position: relative;
    input[type=text] {
      &:focus {
        outline: 3px $brand-primary solid;
        color: $gray-darker;
        background: white;
        + button .search {
          color: $brand-primary;
        }
      }
      border: none;
      padding: 10px;
      min-width: 255px;
      color: $gray;
      border: $input-btn-border-width solid $input-border-color;
    }
    button[type=submit] {
      position: absolute;
      background: none;
      border: none;
      bottom: 0.3125rem;
      right: 0.125rem;
      color: $gray;
      .search:hover {
        color: $brand-primary;
      }
    }
  }
}

// transform
@mixin scale($ratio) {
  -webkit-transform: scale($ratio);
     -moz-transform: scale($ratio);
      -ms-transform: scale($ratio);
       -o-transform: scale($ratio);
          transform: scale($ratio);
}
@mixin translate($x: 0, $y: 0) {
  -webkit-transform: translate($x, $y);
     -moz-transform: translate($x, $y);
      -ms-transform: translate($x, $y);
       -o-transform: translate($x, $y);
          transform: translate($x, $y);
}
@mixin transition($transition:all .5s ease-in-out) {
  -webkit-transition: $transition;
     -moz-transition: $transition;
      -ms-transition: $transition;
       -o-transition: $transition;
          transition: $transition;
}

// box-shadow
@mixin box-shadow($shadow: none) { 
  /*2px 2px 8px 0px rgba(0, 0, 0, 0.2)*/
  -webkit-box-shadow: $shadow;
     -moz-box-shadow: $shadow;
          box-shadow: $shadow;
}
@mixin box-shadow-light($shadow: none) { 
  /*2px 2px 4px 0px rgba(0, 0, 0, 0.2)*/
  -webkit-box-shadow: $shadow;
     -moz-box-shadow: $shadow;
          box-shadow: $shadow;
}

// aspect-ratio
@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  img, iframe {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

// buttons

@mixin buttonColor($color:$main-color, $color-h:$main-color) {
  color: $color;
  &:hover, &:focus {
    color: $color-h;
  }
}
@mixin buttonBorder($color, $color-h) {
  border: 2px solid $color;
  &:hover, &:focus {
    border: 2px solid $color-h;
  }
}
@mixin buttonBackground($color, $color-h) {
  background: $color;
  &:hover, &:focus {
    background: $color-h;
  }
}

@mixin btn {
  display: inline-block;
  padding: 10px 15px;
  font-family: $ff;
  font-size: $font-size-base;
  font-weight: 600;
  @include buttonColor;
  letter-spacing: 1px;
  text-transform: none;
}

@mixin btn-default {
  @include btn;
  @include buttonBackground($white, #e3e3e3);
}

@mixin btn-link {
  @include btn;
  @include buttonColor($main-color, $second-color);
  @include buttonBackground(transparent, transparent);
  padding-left: 0;
  padding-right: 0;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    background-image: url(../../assets/images/arrow-color@x2.png);
    background-repeat:  no-repeat;
    background-position: 0 center;
    background-size: 185px auto;
    width: 185px; height: 20px;
    left: 0; bottom: -1rem;
    @include transition();
    @include filter(brightness(0));
  }
  &:hover:after {
    @include transition();
    @include filter(brightness(1));
  }
}
@mixin btn-more {
  @include btn;
  @include buttonBackground($tertiary-color, $tertiary-color-hover);
}
@mixin btn-cta {
  @include btn;
  @include buttonColor($white, $white);
  @include buttonBackground($second-color, darken($second-color, 5%));
}

@mixin filter($value) {
  -webkit-filter: $value;
     -moz-filter: $value;
       -o-filter: $value;
          filter: $value;
}