.top-menu {
  &[data-depth="1"] {
    margin: 0.625rem;
  }
  a:not([data-depth="0"]) {
    display: block;
    padding: 0.625rem;
    color: $gray;
    font-weight: 400;
  }
  a.dropdown-submenu {
    color: $gray-darker;
    text-transform: uppercase;
    font-weight: 600;
  }
  a[data-depth="0"] {
    font-weight: 600;
    padding: 0.1875rem $small-space 0.375rem;
    &:hover {
      #header & {
        color: $brand-primary;
        text-decoration: none;
      }
    }
  }
  a[data-depth="1"],
  a[data-depth="2"] {
    padding: 0 0.625rem 0.625rem 0;
  }
  .collapse {
    display: inherit;
  }
  .sub-menu {
    &.collapse {
      display: none;
    }
    box-shadow: 2px 1px 11px 2px rgba(0, 0, 0, 0.1);
    border: none;
    margin-left: 0.9375rem;
    width: calc(100% - 30px);
    min-width: calc(100% - 30px);
    z-index: 18;
    display: none;
    ul[data-depth="1"] > li {
      float: left;
      margin: 0 1.25rem;
    }
    a:hover {
      color: $brand-primary;
    }
  }
  .popover {
    max-width: inherit;
    border-radius: 0;
  }
}
.popover.bs-tether-element-attached-top {
  margin-top: 0;
}

#_desktop_top_menu {
  .top-menu {
    &[data-depth="0"] {
      li {
        &:hover {
          .sub-menu {
            display: block !important;
          }
        }
      }
    }
  }
}

#mobile_top_menu_wrapper {
  padding: 15px;
  background: white;
  #top-menu {
    margin-bottom: 0.625rem;
  }
  .top-menu {
    color: $gray-darker;
    .collapse-icons[aria-expanded="true"] {
      .add {
        display: none;
      }
      .remove {
        display: block;
      }
    }
    .collapse-icons .remove {
      display: none;
    }
    .navbar-toggler {
      display: inline-block;
      padding: 0;
    }
    a[data-depth="0"] {
      padding: 0.625rem;
      border-bottom: 1px solid $gray-lighter;
    }
    .collapse {
      display: none;
      &.in {
        display: block;
      }
    }
    .sub-menu {
      &.collapse {
        display: none;
        &.in {
          display: block;
        }
      }
      box-shadow: none;
      z-index: inherit;
      display: block;
      position: static;
      overflow: hidden;
      margin-left: 0;
      width: 100%;
      min-width: 100%;
      background: white;
      ul[data-depth="0"] > li {
        border-bottom: 1px solid $gray;
      }
      ul[data-depth="1"] {
        margin: 0;
        > li {
          float: none;
          margin: 0;
          a {
            text-transform: none;
          }
        }
      }
      ul {
        padding: 0;
      }
      li > a {
        padding: 0.625rem;
        border-bottom: 1px solid white;
        font-weight: bold;
      }
      ul[data-depth="2"] li a {
        padding-left: 1.25rem;
      }
      ul[data-depth="3"] li a {
        padding-left: 2.5rem;
      }
      ul[data-depth="4"] li a {
        padding-left: 3.75rem;
      }
    }
  }
  .js-top-menu-bottom {
    a {
      color: $gray;
    }
    .language-selector-wrapper {
      padding: 0.625rem;
      .language-selector {
        display: inline;
      }
    }
    .currency-selector {
      padding: 0.625rem;
    }
    #contact-link {
      padding: 0.625rem;
    }
    .user-info {
      padding: 0 0.625rem;
      a {
        padding: 0.625rem 0;
        display: block;
        width: 100%;
      }
    }
  }
}
