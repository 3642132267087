
.forgotten-password {
  padding: 4px;

  .form-fields {

    .center-email-fields {
      display: flex;
      justify-content: center;

      @media (max-width: 767px) {
        flex-direction: column;

        button {
          margin: 10px;
          width: calc(100% - 20px);
        }
      }

      button {
        height: 38px;
      }
    }

    .email {
      padding-left: 0;
      padding-right: 0;
      width: 430px;

      @media (max-width: 767px) {
        padding-left: 10px;
        padding-right: 10px;
        width: 100%;
      }

      input {
        height: 38px;
      }
    }

    label.required {
      width: 130px;
    }
  }
}

.send-renew-password-link {
  padding-left: 10px;
  padding-right: 10px;
}
