//== Media queries breakpoints

// Extra small screen / phone
$screen-xs:               576px;
$screen-xs-min:           $screen-xs;

// Small screen / tablet
$screen-sm:               768px;
$screen-sm-min:           $screen-sm;

// Medium screen / desktop
$screen-md:               992px;
$screen-md-min:           $screen-md;

// Large screen / wide desktop
$screen-lg:               1200px;
$screen-lg-min:           $screen-lg;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max:           ($screen-sm-min - 1);
$screen-sm-max:           ($screen-md-min - 1);
$screen-md-max:           ($screen-lg-min - 1);

$screen:                  "only screen";
$xs-up:                   $screen;
$xs-only:                 "#{$screen} and (max-width: #{$screen-xs-max})";

$sm-up:                   "#{$screen} and (min-width: #{$screen-sm-min})";
$sm-only:                 "#{$screen} and (min-width: #{$screen-sm-min}) and (max-width: #{$screen-sm-max})";

$md-up:                   "#{$screen} and (min-width: #{$screen-md-max})";
$md-only:                 "#{$screen} and (min-width: #{$screen-md-min}) and (max-width: #{$screen-md-max})";

$lg-up:                   "#{$screen} and (min-width: #{$screen-lg-min})";

//** Point at which the navbar becomes uncollapsed.
$grid-float-breakpoint:     $screen-md-min;
//** Point at which the navbar begins collapsing.
$grid-float-breakpoint-max: ($grid-float-breakpoint - 1);

//== Custom variable bootstrap overwrite
/* https://github.com/twbs/bootstrap-sass/blob/master/assets/stylesheets/bootstrap/_variables.scss */

$container-width:         1470px !default;
$container-width-max:         ($container-width + 30);

/* Colors */
$cyan:                    #01ced1;
$cyan-light:              #d3feff;
$cyan-light-hover:        #aafdff;
$cyan-lighter:            #e4feff;

$white:                   #fff;
$black:                   #000;
$light:                   #e3e3e3;
$dark:                    #232323;
  
$gray:                    #7A7A7A;
$gray-darker:             #878787;
$gray-light:              #ededed;
$gray-lighter:            #f6f6f6;

$main-color:              $black;
$second-color:            $cyan;
$tertiary-color:          $cyan-light;
$tertiary-color-hover:    $cyan-light-hover;

$brand-primary:           $second-color;
$brand-secondary:         $main-color;



$body-background-color:   $white;

$brand-success:           $cyan  !default; /*#01A58D*/
$brand-warning:           #F38630 !default; /*#FFA033*/
$brand-danger:            #9E1C10 !default; /*#D94343*/

$warning:                 #F38630;
$warning-hover:           #FA6900;

/* fonts */
$font-family-base:        'Montserrat', Arial, sans-serif !default;
$font-family-sans-serif:  'Montserrat', Arial, sans-serif !default;
$font-family-serif:       'Montserrat', Arial, sans-serif !default;

$ff:                      $font-family-base;
$ff-titre:                $font-family-sans-serif;
$ff-serif:                $font-family-serif;

$headings-font-family:    $ff-titre;
$headings-line-height:    1.2em;
$headings-font-weight:    600;

$headings-color:          $black;
$text-color:              $black;
$link-color:              $black;
$link-hover-color:        $second-color;
$link-hover-decoration:   underline;

$base-font-size:          0.875rem; /* 14px */
$font-size-base:          $base-font-size; /* 14px */
$line-height-base:        1.5 !default; /* 24px */

$font-size-lg:            1.125rem; /* 18px */
$font-size-sm:            0.875rem; /* 14px */
$font-size-xs:            0.750rem; /* 12px */

$font-size-h1:            3.000rem; /* 42px // 3.750rem > 60px */
$font-size-h2:            2.500rem; /* 35px */
$font-size-h3:            1.563rem; /* 25px */
$font-size-h4:            1.125rem; /* 18px */
$font-size-h5:            1.125rem; /* 18px */
$font-size-h6:            0.875rem; /* 14px */


$label-color:             $main-color;

$btn-primary-color:       $white;
$btn-primary-bg:          $brand-primary;
$btn-primary-border:      transparent;
$btn-secondary-color:     $black;
$btn-secondary-bg:        $cyan-light;
$btn-secondary-border:    transparent;

$btn-warning-bg: $warning;
$btn-warning-color: white;
$btn-tertiary-color: #6C868E;
$btn-tertiary-hover: #BBCDD2;

$border-radius-base:      0;
$border-radius:           $border-radius-base;
$btn-border-radius:       $border-radius;
$input-border-radius:     $border-radius;

$input-bg:                #fff;
// $input-bg-disabled:    $gray-light;
$input-color:             $main-color;
$input-border:            $main-color;
$input-border-color:      $main-color;
$input-border-focus:      $second-color;

$input-color-placeholder: $main-color;
// $input-height-base:       ($line-height-computed + ($padding-base-vertical * 2) + 2);

$tooltip-bg:              $brand-danger;

$extra-small-space:       0.3125rem;
$small-space:             0.625rem;
$medium-space:            1.25rem;
$large-space:             1.875rem;
$extra-large-space:       2.5rem;


$alert-warning-bg:        rgba(255,154,82,0.3);
$alert-warning-border:    $warning;
$alert-warning-text:      $gray-darker;

$btn-warning-bg:          $warning;
$btn-warning-color:       $white;
$btn-tertiary-color:      #6C868E;
$btn-tertiary-hover:      #BBCDD2;


$display1-size:           3.125rem;
$display1-weight:         600;
$display2-size:           2.188rem;
$display2-weight:         400;
$display3-size:           1.563rem;
$display3-weight:         400;
$display4-size:           1.25rem;
$display4-weight:         400;

$text-muted:              $gray;

$NotoSansPath:            "~notosans-fontface/fonts";
$NotoSansVersion:         "1.0.1";

$icon-font-path:          "../ldw-base/framework/vendors/bootstrap/fonts/";
/* Fontawesome */
$fa-font-path:            "../ldw-base/framework/vendors/font-awesome/fonts";

/* Variable mixins */
/* Button */
/* Btn, Button */
$btn-font-family:   $ff;
$btn-font-size:     $font-size-base;
$btn-font-weight:   600;
$btn-color:         $main-color;
$btn-color-hover:   $second-color;
$btn-padding:       10px 15px;
$btn-border:        0 none;
$btn-border-radius: 0px;

/* box-shadow */
$shadow: 0px 0px 25px 0 rgba(0,0,0,.2);
$shadow-light: 0px 0px 15px 0px rgba(0, 0, 0, 0.2);